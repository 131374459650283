.pages {
  h1 {
    @include BodyText();
  }

  h6 {
    @include BodyText();
  }

  p {
    @include BodyText();
  }

  position: absolute;
  // z-index: 2;
  height: 100%;
  width: 100%;
  padding: 0rem 0.6rem 0rem 0.6rem;
  overflow: hidden;

  .title {
    margin-bottom: 1.4rem;
    // cursor: default;
  }

  .text-body {
    a {
      color: $yellow;
      text-decoration: none;
    }

    @media (hover: hover) {
      a:hover {
        background-color: $yellow;
        color: $black;
      }
    }
  }
}

.pages-about {
  h1 {
    @include BodyText();
  }

  h6 {
    @include BodyText();
  }

  p {
    @include BodyText();
    line-height: 1.5rem;
  }

  position: absolute;
  height: auto;
  width: 100%;
  padding: 0rem 0.6rem 0rem 0.6rem;
  overflow: hidden;

  .title {
    margin-bottom: 1.4rem;
  }

  .text-body {
    // cursor: default;

    a {
      color: $lightgrey;
      text-decoration: none;
      @media (min-width: $smallWidth1-1) {
        cursor: pointer;
      }
    }

    @media (hover: hover) {
      a:hover {
        // background-color: $yellow;
        // color: $black;
      }
    }
  }
}

.pages-project-info-1 {
  position: absolute;
  z-index: -23;
  width: 100%;
  height: auto;
}

.pages-project-info {
  h1 {
    @include BodyText();
  }

  h6 {
    @include BodyText();
  }

  p {
    @include BodyText();
    font-style: normal;
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.1rem;
    text-align: justify;
  }

  // position: absolute;
  // z-index: -23;
  height: auto;
  width: 45%;
  margin: auto;
  padding: 0rem 0.6rem 0rem 0.6rem;
  overflow: hidden;

  @media (max-width: 1200px) {
    width: 55%;
  }

  @media (max-width: $smallWidth1) {
    width: 70%;
  }

  @media (max-width: $smallWidth2) {
    width: 90%;
  }

  .title {
    margin-bottom: 1.4rem;
  }

  .text-body {
    // cursor: default;

    a {
      color: $lightgrey;
      text-decoration: none;
      @media (min-width: $smallWidth1-1) {
        cursor: pointer;
      }
    }
  }
}