.projects {
  position: absolute;
  // z-index: -5;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
  height: 100%;
  padding-top: 8rem;
  padding-bottom: 3.4rem;
  overflow: auto;

  // display: none;

  ul {
    list-style-type: none;

    li {
      display: flex;
      flex-direction: row;
    }
  }
}

.individual-project {
  display: flex;

  .col-1 {
    width: 16rem;

    @media (max-width: $smallWidth1) {
      width: 14rem;
      @media (max-width: $smallWidth3) {
        width: 11rem;
      }
    }
  }

  .col-2 {
    h1,
    h6 {
      color: $dark-grey !important;
      padding-left: 0.4rem;
      @include ProjectsDescription();
      color: $lightgrey !important;
    }

    // cursor: default;
  }

  .hover_img .linkss {
    position: relative;
    text-decoration: none;
  }
  .hover_img .linkss span {
    position: absolute;
    z-index: -99;
    display: none;

    img {
      position: fixed;
      margin: auto;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      padding-left: 20%;
      padding-right: 20%;
      width: 100%;

      @media (max-width: $smallWidth1) {
        padding-left: 12%;
        padding-right: 12%;

        @media (max-width: $smallWidth3) {
          padding-left: 0%;
          padding-right: 0%;
        }
      }
    }
  }

  @media (hover: hover) {
    .col-1 h6:hover {
      background-color: $white;
      color: $black;
  
      cursor: crosshair;
      @media (max-width: $smallWidth1) {
        cursor: crosshair;
      }
    }
  }
  

  // .hover_img a:hover {
  //   // background-color: $white;
  // }

  @media (hover: hover) {
    .hover_img .linkss:hover span {
      display: block;
      // opacity: 1;
    }
  }
  

  .project-rollover-image {
    position: absolute;
    z-index: -2;

    img {
      margin: auto;
      display: block;
      width: 70%;

      @media (max-width: $smallWidth1) {
        width: 80%;

        @media (max-width: $smallWidth3) {
          width: 90%;
        }
      }
    }
  }
}

.interactive-area {
  // position: absolute;
  z-index: -50;
  margin: auto;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: $smallWidth1) {
  // .interactive-area {
  //   // display: none;
  //   width: 50%;
  //   height: 50%;
  // }
  .p5Canvas {
    width: 300px !important;
    height: 200px !important;
  }
}
