@import "./variables";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;

  color: $white;
  //   color: $black;
  //cursor: none !important;

  ::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
  }
  /* Optional: show position indicator in red */
  // ::-webkit-scrollbar-thumb {
  //   background: #FF0000;
  // }
}

body {
  background: $black !important;
  // overflow: hidden;
  cursor: crosshair !important;
  width: 100%;
  height: 100%;

  // cursor: none !important;

  // .mycursor {
  //   display: none;
  // }

  // @media (hover:hover) {
  //   cursor: none;

  //   .mycursor {
  //     display: block;
  //   }
  // }
}

a {
  cursor: crosshair !important;
}

.mycursor {
  width: 0rem;
  height: 0rem;
  border: 0px solid $yellow;
  background: $yellow;
  overflow: visible;
  border-radius: 50%;
  position: absolute;
  z-index: 200;
  transform: translate(-50%, -50%);
  // pointer-events: none;
  transition: all 0.3s ease;
  transition-property: background, transform;
  // opacity: 100%;

  // backdrop-filter: invert(100%);
  // backdrop-filter: blur(2px) invert(100%);
  // backdrop-filter: brightness(60%);
  // backdrop-filter: contrast(40%);
  // backdrop-filter: drop-shadow(4px 4px 10px blue);
  // backdrop-filter: grayscale(30%);
  // backdrop-filter: hue-rotate(120deg);
  // backdrop-filter: invert(100%);
  // backdrop-filter: opacity(50%);
}

.link-grow {
  background: none;
  backdrop-filter: blur(2px) invert(100%);
}

.homepage {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
}

@import "./navigation";
@import "./pages.scss";
@import "./projects";
@import "./about";
@import "./project-info";
