.nav {
  position: relative;
  z-index: 10;

  h1 {
    @include NavTitles();

    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .nav-title {
    position: fixed;
    width: auto;
  }

  .nav-about {
    position: fixed;
    float: right;
    width: auto;
    top: 0;
    right: 0;
  }

  .nav-contact {
    position: fixed;
    float: right;
    width: auto;
    bottom: 0;
  }

  .nav-ig {
    position: fixed;
    width: auto;
    bottom: 0;
    right: 0;
  }

  a {
    text-decoration: none;
  }

  h1 {
    text-decoration: none;
  }

  @media (hover: hover) {
    a:hover {
      cursor: default;

      @media (max-width: $smallWidth1) {
        cursor: default;
      }
    }
  }
}

.interactive-area {
  // position: absolute;
  z-index: -50;
  margin: auto;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
