.project-link {
    text-decoration: none;
}



.project-info {

    // background-color: red;
    margin-top: 8rem;
    margin-bottom: 8rem;
    // padding: 1rem;

    p {
        padding-top: 0.1rem;
        padding-bottom: 0.6rem;

        @media (max-width: $smallWidth1) {
            padding: 0;
        }
    
        @media (max-width: $smallWidth2) {

        }
    }

    h6 {

        font-size: 1rem;

    }

    

    .project-description {
        // Something here to style the content in the 'section' section
    }

    img {
        margin-top: 1rem;
        margin-bottom: 7rem;
        width: 100%
    }

    .player {
        //padding-top: 200px;
    }

    .videoWrapper {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        // padding-bottom: 20%; /* 5:1 */
        padding-top: 25px;
        height: 0;
        // margin-bottom: 5rem;
        margin-top: 20px;
    }
    .videoWrapper iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .synapse-relapse {
        img {
            margin-top: 5rem;
            margin-bottom: 5rem;
            @media (max-width: $smallWidth2) {
                margin-top: 3rem;
                margin-bottom: 3rem;
            }
        }

        iframe {
            // margin-bottom: 4rem;
            width: 100%;
            height: 100%;
        }

        .videoWrapper {
            position: relative;
            // padding-bottom: 56.25%; /* 16:9 */
            padding-bottom: 20%; /* 5:1 */
            padding-top: 25px;
            height: 0;
            margin-bottom: 5rem;

            @media (max-width: $smallWidth2) {
                margin-bottom: 3rem;
            }
        }
        .videoWrapper iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    
}