// Colours
$white: #000000; // #f1f1f1
$black: #e9e9e9; // #0e0e0e;
$red: #ed1c24;
$light-red: #ff6161;
$yellow: #ff4800; //#e4ff00
$orange: #ff4800;
$blue: #0400ff;
$lightgrey: #a3a3a3;
$dark-grey: #2c2c2c;

// Screen Widths
$smallWidth1: 968px;
$smallWidth1-1: 969px;
$smallWidth2: 768px;
$smallWidth3: 500px;

// Typography
@mixin NavTitles {
  // Neue Haas Grotesk
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 500;
  font-style: normal;

  font-size: 2.6rem;

  @media (max-width: $smallWidth1) {
    font-size: 2.2rem;

    @media (max-width: $smallWidth3) {
      font-size: 1.4rem;
    }
  }
}

@mixin BodyText {
  // Neue Haas Grotesk
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 500;
  font-style: normal;

  font-size: 0.95rem;
  line-height: 2rem;

  @media (max-width: $smallWidth1) {
    font-size: 0.95rem;
    line-height: 1.4rem;

    @media (max-width: $smallWidth3) {
      font-size: 0.8rem;
      line-height: 1.2rem;
    }
  }
}

@mixin ExternalLinks {
  // Neue Haas Grotesk
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 500;
  font-style: italic;

  font-size: 0.95rem;
  line-height: 2rem;

  @media (max-width: $smallWidth1) {
    font-size: 0.95rem;
    line-height: 1.4rem;

    @media (max-width: $smallWidth3) {
      font-size: 0.8rem;
      line-height: 1.2rem;
    }
  }
}

@mixin ProjectsDescription {
  // Neue Haas Grotesk
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 500;
  // font-style: italic;
  // font-color: rgb(150, 0, 0);

  font-size: 0.95rem;
  line-height: 2rem;

  @media (max-width: $smallWidth1) {
    font-size: 0.95rem;
    line-height: 1.4rem;

    @media (max-width: $smallWidth3) {
      font-size: 0.8rem;
      line-height: 1.2rem;
    }
  }
}
