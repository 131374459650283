.about-contact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 8rem;

  a {
    color: $orange !important;
    // text-decoration: underline !important;
    font-weight: 300 !important;
    font-style: normal !important;
    // text-decoration: underline overline !important;
  }



  .about {
    // display: none;
    max-width: 42rem;
  }

  @media (max-width: $smallWidth1) {
    .about {
      // padding-bottom: 3rem;
    }

    @media (max-width: $smallWidth3) {
      margin-top: 4rem;
      padding-bottom: 2rem;
    }
  }

  //   max-width: 40%;

  .contact {
    margin-top: 5rem;
    padding-bottom: 4rem;

    @media (max-width: $smallWidth1) {
      margin-top: 3rem;
      padding-bottom: 3rem;
      @media (max-width: $smallWidth3) {
        margin-top: 2rem;
        padding-bottom: 4rem;
      }
    }
  }

  a {
    @include ExternalLinks();
  }
}
